export const HOME = 'home'
export const ABOUT_US = 'about_us'
export const LEGAL = {
  DATA_PROTECTION: 'data_protection',
  GET_VISITOR_ID: 'get_visitor_id',
  MENTIONS: 'legal_mentions',
  COOKIES: 'cookies',
  COOKIES_TERMS: 'cookies_terms_conditions',
}

export const PRODUCT = {
  HOME: 'product',
  PREVIEW: 'product_preview',
}

export const LANDING = {
  MERCHANT: 'merchant_landing',
  SEARCH: 'search_page',
  PRODUCT: 'landing_page',
  SORT: 'sort',
  FILTERS: 'filters',
  FILTERS_DETAILS: 'filters_details',
  FILTERS_DETAILS_PRICE: 'filters_details_price',
  UNIVERSE: 'universe_page',
}

export const KYB = {
  HOME: 'kyb',
  SUCCESS: 'kyb_success',
  FAILURE: 'kyb_failure',
}
export const OLD_MERCHANT_ORDERS = 'merchant_orders'
export const CLIENTS = 'clients'
export const ADMIN = {
  HOME: 'admin',
}

export const SAV_PLATFORM = {
  HOME: 'sav_platform',
  AFTER_SALES_CARE_FOLDER_DETAILS: 'after_sales_care_folder_details',
  CUSTOMER_REQUESTS: {
    ALL: 'sav_platform_customer_requests_all',
    CLOSED: 'sav_platform_customer_requests_closed',
    PENDING: 'sav_platform_customer_requests_pending',
    RETURNS: 'sav_platform_customer_requests_returns',
    STANDALONE_CONVERSATION:
      'sav_platform_customer_requests_standalone_conversation',
    STANDALONE_CONVERSATION_RESOLUTION:
      'sav_platform_customer_requests_standalone_conversation_resolution',
  },
  INVOICE_REQUESTS: 'sav_platform_invoice_requests',
  ALL_CUSTOMER_REQUESTS: 'sav_platform_all_customer_requests',
}

export const SAV_PLATFORM_ADMIN = {
  HOME: 'admin_sav_platform',
  CUSTOMER_REQUESTS: 'sav_platform_admin_customer_requests',
  RESPONSE_TEMPLATES: 'sav_platform_admin_response_templates',
}

export const SAV_PLATFORM_BACKCARE = {
  HOME: 'backcare_sav_platform',
  CUSTOMER_REQUESTS: 'sav_platform_backcare_customer_requests',
}

export const BUYBACK = {
  CATEGORIES: 'buyback_categories',
  CUSTOMER: {
    RECYCLING_CATEGORY: 'recycling_category',
    RECYCLING_OFFER: 'recycling_offer',
    DASHBOARD: {
      CONVERSATION: 'buyback_customer_conversation',
    },
  },
  REFURBISHER: {
    CONVERSATION: 'buyback_refurbisher_conversation',
  },
  STAFF: {
    ORDER: 'buyback_staff_order',
    CONVERSATION: 'buyback_staff_conversation',
  },
  MERCHANT: {
    HOME: 'buyback_merchant',
    ACH_UPDATE: 'buyback_merchant_update_ach',
    UPDATE_CREDIT_CARD: 'buyback_merchant_update_cc',
    UPDATE_CREDIT_CARD_SUCCESS: 'buyback_merchant_credit_card_update_success',
    UPDATE_CREDIT_CARD_FAIL: 'buyback_merchant_credit_card_update_failure',
    SEPA_UPDATE: 'buyback_merchant_sepa_update',
    SEPA_UPDATE_SUCCESS: 'buyback_merchant_sepa_update_success',
    SEPA_UPDATE_FAIL: 'buyback_merchant_sepa_update_failure',
    BACS_UPDATE: 'buyback_merchant_bacs_update',
  },
  DEVICE_FORM: 'buyback_device_form',
  OFFER: 'buyback_offer',
  NO_OFFER: 'buyback_no_offer',
  BANK: 'buyback_bank',
  ADDRESS: 'buyback_address',
  SHIPPING: 'buyback_shipping',
  CONFIRMATION: 'buyback_confirmation',
}

export const ADMIN_TOOLS = {
  HOME: 'admin_tools',
  TOOLS: {
    TASK_ANALYZER: 'admin_tools_tools_task_analyzer',
    FIELD_VALIDATION_PLAYGROUND:
      'admin_tools_tools_field_validation_playground',
    LIVE_IDENTITY_CHECK: 'admin_tools_live_identity_check',
  },
  LANDING_PAGES: {
    BULK_IMPORTS: 'admin_tools_landing_page_bulk_imports',
  },

  CATALOG: {
    TOOLS: {
      BULK_ACTIONS: 'admin_tools_catalog_tools_bulk_action',
      AKENEO: {
        IMAGES: {
          UPLOAD: 'admin_tools_catalog_tools_images_upload',
        },
      },
    },
    VALIDATION: {
      PRODUCTS: {
        LIST: 'admin_tools_catalog_validation_products_list',
        HISTORY: 'admin_tools_catalog_validation_products_history',
        REMATCH: {
          ITEM: 'admin_tools_catalog_validation_products_rematch_item',
        },
      },
      LISTINGS: {
        REMATCH: 'admin_tools_catalog_validation_listings_rematch',
      },
    },
    SALES: {
      PRODUCTS: {
        UPDATE: 'admin_tools_sales_product_update',
        LIST: 'admin_tools_sales_product_list',
      },
      LISTINGS: {
        LIST: 'admin_tools_sales_listings_list',
        LIFECYCLE: {
          ONLINE_CONDITIONS:
            'admin_tools_sales_listings_lifecycle_online_conditions',
          HISTORY: 'admin_tools_sales_listings_lifecycle_history',
          INDEX: 'admin_tools_sales_listings_lifecycle',
        },
      },
      CATEGORIES: {
        RESTRICTED: {
          LIST: 'admin_tools_sales_categories_restricted_list',
        },
      },
    },
    BUYBACK: {
      CATEGORIES: {
        LIST: 'admin_tools_catalog_buyback_categories_list',
      },
      LISTINGS: {
        SEARCH: 'admin_tools_catalog_buyback_listings_search',
      },
      MERCHANT: {
        PRODUCT: {
          LISTINGS: 'admin_tools_catalog_buyback_merchant_product_listings',
        },
      },
    },
    PRODUCTS: {
      LIST: 'admin_tools_catalog_akeneo_products_list',
      ITEM: {
        AKENEO: 'admin_tools_catalog_akeneo_products_item_akeneo',
        BUYBACK: {
          BACKBOXES:
            'admin_tools_catalog_akeneo_products_item_buyback_backboxes',
          LISTINGS:
            'admin_tools_catalog_akeneo_products_item__buyback_listings',
        },
      },
    },
    SELLERS: {
      ITEM: {
        BUYBACK_LISTINGS: 'admin_tools_sellers_item_buyback_listings',
        RATE_LIMITING: 'admin_tools_sellers_item_rate_limiting',
      },
    },
  },
  REPAIR: {
    SELLER: 'admin_tools_repair_seller',
  },
  CUSTOMERS: {
    INDEX: 'admin_tools_customers',
    ACCOUNT: 'admin_tools_customers_account',
    PERSONAL_DATA: 'admin_tools_customers_personal_data',
  },
  SALES: {
    LIMITATIONS: 'admin_tools_sales_limitations',
    RATINGS_REVIEWS: 'admin_tools_sales_ratings_reviews',
    ORDERS: 'admin_tools_sales_orders',
    PROMOTION_CODES: 'admin_tools_sales_promotion_codes',
    TRANSACTIONS: {
      HISTORY: 'admin_tools_sales_transactions_history',
    },
    REFUNDS: 'admin_tools_sales_refunds',
  },
  SOURCING: {
    ORDERS: 'admin_tools_sourcing_orders',
    ORDERS_LIST: 'admin_tools_sourcing_orders_list',
  },
  INSURANCES: {
    CANCEL: 'admin_tools_insurances_cancel',
    STATUS: 'admin_tools_insurances_status',
  },
  BACKSHIP: {
    BILLING: {
      INVOICES: 'admin_tools_backship_billing_invoices',
      SELLER_CHARGES: 'admin_tools_backship_billing_seller_charges',
      SELLER_CHARGES_MARGINS: {
        CARRIER_BACKSHIP_MARGINS:
          'admin_tools_backship_billing_seller_charges_carrier_backship_margins',
        TRADELINE_BACKSHIP_MARGINS:
          'admin_tools_backship_billing_seller_charges_tradeline_backship_margins',
        SELLER_BACKSHIP_MARGINS:
          'admin_tools_backship_billing_seller_charges_seller_backship_margins',
      },
    },
    TRADELINES: 'admin_tools_backship_tradelines',
    CATEGORY_PARCEL_DIMENSIONS:
      'admin_tools_backship_category_parcel_dimensions',
    PROMISE_BUFFER_DISTURBANCES:
      'admin_tools_backship_promise_buffer_disturbances',
  },
  FINANCE: {
    PICSOU: 'admin_tools_finance_picsou',
    CREDIT_REQUESTS: 'admin_tools_finance_credit_requests',
    CCBM_FEE_RULES: 'admin_tools_finance_ccbm_fee_rules',
    REASONS: 'admin_tools_reasons',
    REASONS_OLD: 'admin_tools_reasons_old',
    SELLER_COMPENSATIONS: 'admin_tools_seller_compensations',
    TAX_COLLECTION: 'admin_tools_tax_collection',
    CARRIER_INVOICE: 'admin_tools_carrier_invoice',
  },
  ORDERS: {
    MANUAL_REFUNDS: 'admin_tools_orders_manual_refunds',
  },
}

export const BO_MERCHANT = {
  ROOT: 'bo_merchant',
  HOME: 'bo_merchant_home',
  CUSTOMER_REVIEWS: 'customer_reviews',
  LOGIN: 'bo_merchant_login',
  REGISTER: 'bo_merchant_register',
  PASSWORD_RESET: 'bo_merchant_password_reset',
  DASHBOARD: 'bo_dashboard',
  LISTINGS: {
    CREATE: {
      PRODUCT: {
        CREATE: 'listings_create_product_create',
        SEARCH: 'listings_create_product_search',
        DUPLICATE: 'listing_create_product_duplicate',
      },
      DETAILS: 'listings_create_details',
    },
    ACTIVE: 'listings_active',
    MISSING_INFOS: 'listings_missing_infos',
    IN_VALIDATION: 'listings_in_validation',
    ARCHIVED: 'listings_archived',
    PARENT: 'listings_parent',
  },
  OPTIONS: {
    ADDRESSES: 'bo_options_addresses',
    ADDRESS_BOOK: 'bo_options_address_book',
    BACKPRICER: 'bo_options_backpricer',
    BUYBACK: 'bo_options_buyback',
    HOME: 'bo_options',
    INTEGRATIONS: 'bo_options_integrations',
    KYB: 'bo_options_kyb',
    MARKETS: 'bo_options_markets',
    MY_INFO: 'bo_options_my_info',
    PAYMENT: 'bo_options_payment',
    PRICING: 'bo_options_pricing',
    SERVICES: 'bo_options_services',
    SHIPPING: 'bo_options_shipping',
    TRANSFER_METHODS: 'bo_options_transfer_methods',
    TRANSFER_METHODS_CONFIRMATION: 'bo_options_transfer_methods_confirmation',
    BATCH_LISTING_CREATION: 'bo_options_batch_listing_creation',
  },
  SOURCING: {
    DASHBOARD: 'bo_sourcing_dashboard',
    ORDERS: {
      HOME: 'bo_sourcing_orders',
      ALL: 'bo_sourcing_orders_all',
      ACTIVE: 'bo_sourcing_orders_active',
      PENDING: 'bo_sourcing_orders_pending',
    },
    ORDER: 'bo_sourcing_order',
    LISTINGS: 'bo_sourcing_listings',
    INVOICES: 'bo_sourcing_invoices',
    OPTIONS: 'bo_sourcing_options',
  },
  BUYBACK: {
    ORDER_DETAILS: 'refurbisher_buyback_order_details',
    WISHLIST: 'refurbisher_buyback_wishlist',
    CREATE_QUOTATION: 'refurbisher_buyback_create_quotation',
    SEARCH_PRODUCT: 'refurbisher_buyback_search_product',
  },
  SHIPPING: {
    CREATE_LABEL: 'create_shipping_label',
  },
  INTERNATIONAL_ONBOARDING: {
    BASE_URL: 'international_onboarding_baseURL',
    MARKETS: 'international_onboarding_markets',
    SHIPPING: 'international_onboarding_shipping',
    CUSTOMER_CARE: 'international_onboarding_customer_care',
    PAYMENT: 'international_onboarding_payment',
    PRICING_RULES: 'international_onboarding_pricing_rules',
    REVIEW: 'international_onboarding_review',
    CONFIRMATION: 'international_onboarding_confirmation',
  },
  PERFORMANCES: 'performances',
  INSIGHTS: 'insights',
  INSIGHTS_TABLEAU: 'insights_tableau',
  INVOICES: {
    HOME: 'invoices',
    PENDING: 'invoices_pending',
    WALLET: 'invoices_wallet',
    PAST_INVOICES: 'invoices_past',
    GOODWILL_GESTURES: 'invoices_GOODWILL_GESTURES',
    COMPENSATION: 'invoices_compensation',
  },
  PROFILE: {
    ROOT: 'merchant_profile_root',
    HOME: 'merchant_profile_home',
    LEGAL_DETAILS: 'merchant_profile_legal_details',
    COMPANY_INFO: 'merchant_profile_company_info',
    CONTACT_DETAILS: 'merchant_profile_contact_details',
    USER_PERMISSIONS: 'merchant_profile_user_permissions',
  },
  DOCUSIGN_CGU: 'docusign_cgu',
  QUALITY_CHARTER: 'quality_charter',
  PROFIL: 'merchant_profil',
  MAILBOX: 'mailbox',
  LOGOUT: 'merchant_logout',
  PAYOUT_INFORMATION: 'payout_information',
  PAYPAL_CONNECT_ACCOUNT: 'paypal_connect_account',
  FEEDBACK: 'feedback_page',
  ONBOARDING: {
    // A lot of routes seems duplicated, this is basically to case to create another path for certains pages of the BO for onboarding seller
    ROOT: 'bo_merchant_onboarding',
    HOME: 'bo_merchant_onboarding_home',
    LISTINGS: {
      ROOT: 'onboarding_listings',
      CREATE: {
        PRODUCT: {
          DUPLICATE: 'onboarding_listing_create_product_duplicate',
          SEARCH: 'onboarding_listings_create_product_search',
        },
        DETAILS: 'onboarding_listings_create_details',
      },
      ACTIVE: 'onboarding_listings_active',
      MISSING_INFOS: 'onboarding_listings_missing_infos',
      IN_VALIDATION: 'onboarding_listings_in_validation',
      ARCHIVED: 'onboarding_listings_archived',
    },
    OPTIONS: {
      ADDRESSES: 'onboarding_bo_options_addresses',
      ADDRESS_BOOK: 'onboarding_bo_options_address_book',
      BACKPRICER: 'onboarding_bo_options_backpricer',
      BUYBACK: 'onboarding_bo_options_buyback',
      HOME: 'onboarding_bo_options',
      INTEGRATIONS: 'onboarding_bo_options_integrations',
      KYB: 'onboarding_bo_options_kyb',
      MARKETS: 'onboarding_bo_options_markets',
      MY_INFO: 'onboarding_bo_options_my_info',
      PAYMENT: 'onboarding_bo_options_payment',
      PRICING: 'onboarding_bo_options_pricing',
      SERVICES: 'onboarding_bo_options_services',
      SHIPPING: 'onboarding_bo_options_shipping',
      TRANSFER_METHODS: 'onboarding_bo_options_transfer_methods',
      TRANSFER_METHODS_CONFIRMATION:
        'onboarding_bo_options_transfer_methods_confirmation',
    },
    PROFILE: {
      ROOT: 'onboarding_merchant_profile_root',
      HOME: 'onboarding_merchant_profile_home',
      LEGAL_DETAILS: 'onboarding_merchant_profile_legal_details',
      COMPANY_INFO: 'onboarding_merchant_profile_company_info',
      CONTACT_DETAILS: 'onboarding_merchant_profile_contact_details',
      USER_PERMISSIONS: 'onboarding_merchant_profile_user_permissions',
    },
    FEEDBACK: 'onboarding_feedback_page',
    DOCUSIGN_CGU: 'onboarding_docusign_cgu',
  },
  REVIEWS: 'bo_merchant_reviews',
  ORDERS: {
    ALL: 'bo_merchant_orders_all',
    NEW: 'bo_merchant_orders_new',
    PENDING: 'bo_merchant_orders_pending',
    NEEDING_RESPONSE: 'bo_merchant_orders_needing_response',
  },
  OPPORTUNITIES: {
    PRICING: 'bo_merchant_opportunities_pricing',
  },
  NO_ACCESS: 'bo_merchant_no_access',
  CUSTOMER_CARE: {
    ALL_REQUESTS: 'bo_merchant_customer_care_all_requests',
    OPEN_TASKS: 'bo_merchant_customer_care_open_tasks',
  },
}

export const BO_SELLER = {
  LOGIN: 'bo-seller.login',
  REGISTER: 'bo-seller.register',
  HOME: 'bo-seller.home',
  INSIGHTS: 'bo-seller.insights',
  OPPORTUNITIES: {
    PRICING: 'bo-seller.opportunities.pricing',
  },
  INVOICES: {
    WALLET: 'bo-seller.invoices.wallet',
    PAST_INVOICES: 'bo-seller.invoices.past-invoices',
    GOODWILL_GESTURES: 'bo-seller.goodwill.goodwill-gestures',
    SELLER_COMPENSATION: 'bo-seller.seller-compensation',
  },
  CUSTOMER_CARE: {
    INDEX: 'bo-seller.customer-care',
    OPEN_TASKS: 'bo-seller.customer-care.open-tasks',
    ALL_REQUESTS: 'bo-seller.customer-care.all-requests',
    REQUEST_RESOLUTION: 'bo-seller.customer-care.request-resolution',
    REQUEST: 'bo-seller.customer-care.request',
  },
  CUSTOMER_REVIEWS: 'bo-seller.customer-reviews',
  LISTINGS: {
    PRODUCT: {
      SEARCH: 'bo-seller.listings.product.search',
    },
    CREATE: 'bo-seller.listings.create',
  },
  OPTIONS: {
    BACKPRICER: 'bo-seller.options.backpricer',
    INTEGRATIONS: 'bo-seller.options.integrations',
    MARKETS: 'bo-seller.options.markets',
    ADDRESSES: 'bo-seller.options.addresses',
    SHIPPING: 'bo-seller.options.shipping',
    PAYMENT: 'bo-seller.options.payment',
    HYPERWALLET: {
      KYB: 'bo-seller.options.hyperwallet.kyb',
      TRANSFER_METHODS: 'bo-seller.options.hyperwallet.transfer-methods',
      CONFIRMATION: 'bo-seller.options.hyperwallet.confirmation',
    },
    PRICING: 'bo-seller.options.pricing',
    BATCH_LISTING_CREATION: 'bo-seller.options.batch-listing-creation',
  },
  ORDERS: {
    INDEX: 'bo-seller.orders',
  },
  PROFILE: {
    HOME: 'bo-seller.profile.home',
    COMPANY_INFO: 'bo-seller.profile.company-info',
    LEGAL_DETAILS: 'bo-seller.profile.legal-details',
    CONTACT_DETAILS: 'bo-seller.profile.contact-details',
    USER_PERMISSIONS: 'bo-seller.profile.user-permissions',
  },
  ONBOARDING: {
    // A lot of routes seems duplicated, this is basically to case to create
    // another path for certains pages of the BO for onboarding seller
    INDEX: 'bo-seller.onboarding',
    HOME: 'bo-seller.onboarding.home',
    LISTINGS: {
      INDEX: 'bo-seller.onboarding.listings',
      ACTIVE: 'bo-seller.onboarding.listings.active',
      MISSING_INFOS: 'bo-seller.onboarding.listings.missing-infos',
      IN_VALIDATION: 'bo-seller.onboarding.listings.in-validation',
      ARCHIVED: 'bo-seller.onboarding.listings.archived',
      CREATE: {
        PRODUCT: {
          DUPLICATE: 'bo-seller.onboarding.listing.create-product-duplicate',
          SEARCH: 'bo-seller.onboarding.listings.create-product-search',
        },
        DETAILS: 'bo-seller.onboarding.listings.create-details',
      },
    },
    OPTIONS: {
      INDEX: 'bo-seller.onboarding.options',
      ADDRESSES: 'bo-seller.onboarding.options.addresses',
      ADDRESS_BOOK: 'bo-seller.onboarding.options.address-book',
      BACKPRICER: 'bo-seller.onboarding.options.backpricer',
      BUYBACK: 'bo-seller.onboarding.options.buyback',
      INTEGRATIONS: 'bo-seller.onboarding.options.integrations',
      KYB: 'bo-seller.onboarding.options.kyb',
      MARKETS: 'bo-seller.onboarding.options.markets',
      MY_INFO: 'bo-seller.onboarding.options.my-info',
      PAYMENT: 'bo-seller.onboarding.options.payment',
      PRICING: 'bo-seller.onboarding.options.pricing',
      SERVICES: 'bo-seller.onboarding.options.services',
      SHIPPING: 'bo-seller.onboarding.options.shipping',
      TRANSFER_METHODS: 'bo-seller.onboarding.options.transfer-methods',
      TRANSFER_METHODS_CONFIRMATION:
        'bo-seller.onboarding.options.transfer-methods-confirmation',
    },
    PROFILE: {
      INDEX: 'bo-seller.onboarding.profile',
      HOME: 'bo-seller.onboarding.profile.home',
      COMPANY_INFO: 'bo-seller.onboarding.profile.company-info',
      LEGAL_DETAILS: 'bo-seller.onboarding.profile.legal-details',
      CONTACT_DETAILS: 'bo-seller.onboarding.profile.contact-details',
      USER_PERMISSIONS: 'bo-seller.onboarding.profile.user-permissions',
    },
    SUPPORT: 'bo-seller.onboarding.support',
    FEEDBACK: 'bo-seller.onboarding.feedback',
  },
  BUYBACK: {
    WISHLIST: 'bo-seller.buyback.wishlist',
    CREATE_QUOTATION: 'bo-seller.buyback.create-quotation',
    SEARCH_PRODUCT: 'bo-seller.buyback.search-product',
    ORDERS: {
      ACTIVE: 'bo-seller.buyback.orders.active',
      PENDING: 'bo-seller.buyback.orders.pending',
      ALL: 'bo-seller.buyback.orders.all',
      DETAILS: 'bo-seller.buyback.orders.details',
      CONVERSATION: 'bo-seller.buyback.orders.conversation',
    },
  },
  CREATE_SHIPPING_LABEL: 'bo-seller.shipping.create-label',
}

export const BO_STAFF = {
  CUSTOMERS: {
    INDEX: 'bo-staff.customers',
  },
  BACKSHIP: {
    TRADELINES: 'bo-staff.backship.tradelines',
    BILLING: {
      CARRIER_INVOICES: 'bo-staff.backship.billing.carrier-invoices',
      SELLER_CHARGES: 'bo-staff.backship.billing.seller-charges',
      MARGINS_CONFIGURATION: {
        CARRIER_BACKSHIP_MARGINS:
          'bo-staff.backship.billing.margins-configuration.carrier-backship-margins',
        TRADELINE_BACKSHIP_MARGINS:
          'bo-staff.backship.billing.margins-configuration.tradeline-backship-margins',
        SELLER_BACKSHIP_MARGINS:
          'bo-staff.backship.billing.margins-configuration.seller-backship-margins',
      },
    },
    CATEGORY_PARCEL_DIMENSIONS: 'bo-staff.backship.category-parcel-dimensions',
    PROMISE_BUFFER_DISTURBANCES:
      'bo-staff.backship.promise-buffer-disturbances',
  },
  INSURANCES: {
    CANCEL: 'bo-staff.insurances.cancel',
  },
  SALES: {
    RATINGS_REVIEWS: {
      OVERVIEW: 'bo-staff.sales.ratings-reviews',
      MODERATION: 'bo-staff.sales.ratings-reviews-moderation',
    },
    SALES_LIMITATIONS: 'bo-staff.sales.sales-limitations',
  },
  CATALOG: {
    SALES: {
      PRODUCTS: {
        UPDATE: 'bo-staff.catalog.sales.product.update',
      },
      CATEGORIES: {
        RESTRICTED: {
          LIST: 'bo-staff.catalog.sales.categories.restricted',
        },
      },
      LISTINGS: {
        LIST: 'bo-staff.catalog.sales.listings.list',
        LIFECYCLE: {
          INDEX: 'bo-staff.catalog.sales.lifecycle.market-attributes',
          ONLINE_CONDITIONS:
            'bo-staff.catalog.sales.lifecycle.online-conditions',
          HISTORY: 'bo-staff.catalog.sales.lifecycle.history',
        },
      },
    },
    VALIDATION: {
      PRODUCTS: {
        LIST: 'bo-staff.catalog.validation.products.list',
        HISTORY: 'bo-staff.catalog.validation.products.history',
        REMATCH: {
          ITEM: 'bo-staff.catalog.validation.products.rematch.item',
        },
      },
      LISTINGS: {
        REMATCH: 'bo-staff.catalog.validation.listings.rematch',
      },
    },
    TOOLS: {
      BULK_ACTIONS: 'bo-staff.catalog.tools.bulk-actions',
    },
  },
  TOOLS: {
    FIELD_VALIDATION_PLAYGROUND: 'bo-staff.tools.field-validation-playground',
    TASK_ANALYZER: 'bo-staff.tools.task-analyzer',
    LIVE_IDENTITY_CHECK: 'bo-staff.tools.live-identity-check',
  },
  FINANCE: {
    CREDIT_REQUESTS: 'bo-staff.finance.credit-requests',
  },
  BUYBACK: {
    ORDERS: {
      INDEX: 'bo-staff.buyback.orders',
      CONVERSATION: 'bo-staff.buyback.orders.conversation',
    },
  },
}

export const CMS = {
  ARTICLE: 'article',
  ARTICLE_CATEGORY: 'article-category',
  BACK_STORIES: 'back-stories',
  BACK_STORIES_CATEGORY: 'back-stories-category',
  BRAND_STORE: 'brand-store',
  BRAND_STORE_CATEGORY: 'brand-store-category',
  BUSINESS: 'business-page',
  BUYBACK: 'buyback',
  BUYBACK_CATEGORY: 'buyback-category',
  GIFT_FINDER_MAIN: 'gift-finder',
  GIFT_FINDER_EVENT: 'gift-finder-category',
  EVENT: 'event-page',
  LEGAL_PAGE: 'legal-page',
  LEGAL_PREVIOUS_VERSION: 'legal-document',
  REFER_FRIEND: 'refer-friend',
  SELLER: 'seller',
  STATEMENT: 'accessibility',
}

export const MERCHANT_INFOS = {
  LIST: 'vendors',
}

export const AUTH = {
  LOGOUT: 'logout',
  REGISTER: 'on_boarding',
  VERIFICATION_EMAIL: 'verification_email',
  PASSWORD_RESET: {
    MAIN: 'password_reset',
    CONFIRM: 'password_reset_confirm',
    NEW: 'password_reset_set_new',
    EXPIRED: 'password_reset_expired',
  },
}

export const AUTH_OAUTH2 = {
  LOGIN: 'auth/login',
  LOGOUT: 'auth/logout',
}

export const DASHBOARD = {
  SELF: 'dashboard',
  MY_ORDERS: {
    SELF: 'orders',
    CANCEL: 'cancel',
    WITHDRAW: {
      INSURANCES: 'withdraw_insurances',
      ORDER: 'withdraw_order',
    },
  },
  MY_PETANQUE_ORDERS: 'petanque-orders',
  MY_SALES: 'my_sales',
  FAVORITES: 'favorites',
  RESOLUTION: {
    SELF: 'resolution',
    SELF_FROM_DIAGNOSIS_V2: 'resolution_from_diagnosis_v2',
    RESOLUTION_CONTACT_CONFIRMATION: 'resolution_contact_confirmation',
    RESOLUTION_RETURN_CONFIRMATION: 'resolution_return_confirmation',
    RESOLUTION_STRAIGHT_REFUND_CONFIRMATION:
      'resolution_straight_refund_confirmation',
  },
  DIAGNOSIS: {
    SELF: 'diagnosis',
  },
  KYC: {
    PROFILE: 'profile',
    OTHER: 'dashboard_other',
    REFER_FRIEND: 'refer_friend',
    REFER_FRIEND_PENDING: 'refer_friend_pending',
    DELETE_ACCOUNT: 'delete_my_account',
  },
}

export const HELP_CENTER = {
  HOME: 'help_center',
}

export const REFERRAL_PROGRAM_SHARING_ROUTE = 'refer_friend_sharing_url'

export const CHECKOUT = {
  CART: 'checkout_cart',
  AUTHENTICATION: 'checkout_authentication',
  POST_AUTHENTICATION: 'checkout_post_authentication',
  HELLO: 'checkout_hello',
  LOGIN: 'checkout_login',
  SIGNUP: 'checkout_signup',
  ADDRESS_CONFIRMATION: 'checkout_address_confirmation',
  SHIPPING_ADDRESS: 'checkout_shipping_address',
  BILLING_ADDRESS: 'checkout_billing_address',
  PAYMENT: 'checkout_payment',
  PAYMENT_RESULT: 'checkout_payment_result',
  INSURANCE: 'checkout_insurance',
}

export const CHECKOUT_INSURANCE = {
  AUTHENTICATION: 'checkout_insurance_authentication',
  SESSION: 'checkout_insurance_session',
  PAYMENT: 'checkout_insurance_payment',
  BILLING: 'checkout_insurance_billing',
  EXPIRED: 'checkout_insurance_expired',
}

export const REVIEWS = {
  FORM: 'review_form',
  PAGES: {
    ALL: 'reviews',
    LANDING: 'landing_reviews',
    MERCHANT: 'merchant_reviews',
    PRODUCT: 'product_reviews',
    NEW_PRODUCT: 'new_product_reviews',
  },
}

export const REPAIR = {
  TRACKING: 'repair_tracking',
}

export const MODELS_COMPARISON = {
  HOME: 'models_comparison',
  CATEGORY: 'models_comparison_category',
  COMPARISON_RESULTS: 'models_comparison_comparison_results',
}

export const SAV_CONVERSATION = 'sav'
export const ASP_RESOLUTION_CONVERSATION = 'asp_resolution_conversation'

export const PACKAGE_TRACKING = 'package_tracking'

export const PDF = {
  TERMS_USE: 'terms_use',
  TERMS_USE_PREVIOUS: 'terms_use_previous',
  TERMS_SALE: 'terms_sale',
  TERMS_BUYBACK: 'terms_buyback',
  TERMS_MERCHANT_ONBOARDING: 'terms_merchant_onboarding',
  WARRANTY: 'warranty',
  WARRANTY_PREVIOUS: 'warranty_use_previous',
  CONSUMER: 'refurbish',
  CERTIFICATE_TO_TRANSPORT_INVESTIGATION:
    'certificate_to_transport_investigation',
}

export const STUDENT_DISCOUNT = 'student_discount'
export const MILITARY_DISCOUNT = 'military_discount'

export const DIAGNO = 'diagno'

export const BUYING_GUIDE = {
  ROOT_HUB: 'root_hub_buying_guide',
  HUB: 'hub_buying_guide',
}
export const CREDIT_CARD = {
  UPDATE: 'credit_card_update',
  UPDATE_SUCCESS: 'credit_card_update_success',
  UPDATE_FAILURE: 'credit_card_update_failure',
}

export const TEST_CHALLENGE = 'test_challenge'

export const FRONT_OFFICE = {
  CUSTOMER_CONVERSATION: {
    RESOLUTION: 'customer_conversation_resolution',
    CARE_FOLDER: 'customer_conversation_carefolder',
  },
}
